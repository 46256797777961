<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>New Recurrent Transaction Template</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text to="/sales/recurrent-templates">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
            <editor :initial="dataItem" @data="save"></editor>
          <!-- end -->
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import Editor from '../_components/editor.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({
    dataItem: {}
  }),
  methods: {
    save(data) {
      const url = "/recurringtransactiontemplates";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/recurrent-templates");
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    }
  }
};
</script>