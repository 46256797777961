<template>
  <v-form>
    <v-row>
      <v-col cols="3">
        <v-text-field label="Template" v-model="record.Code"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Description" v-model="record.Dscription"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="record.DocObjType"
          :items="docTypes"
          item-text="name"
          item-value="value"
          label="Type"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-text-field
          label="Doc No"
          :append-icon="'mdi-airballoon'"
          @click:append="openDraftsDialog"
          v-model="draftDoc.DocNum"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="record.Frequency"
          :items="frequencies"
          item-text="name"
          item-value="value"
          label="Recurrence Period"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="record.Remind"
          :items="reminders"
          item-text="name"
          item-value="value"
          label="Recurrence Date"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <DatePicker @date="setStartDate" :myDate="StartDate" :title="`Start Date`"></DatePicker>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Next Execution" v-model="record.DateVar" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <DatePicker @date="setEndDate" :myDate="EndDate" :title="`Valid Until`"></DatePicker>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Total No. of Instances" v-model="record.Instances" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Bp" v-model="draftDoc.CardCode" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Bp Name" v-model="draftDoc.CardName" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Warehouse" v-model="draftDoc.WhsCode" filled></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="Doc Total" v-model="draftDoc.DocTotal" filled></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Remarks" v-model="record.CardCode" filled></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- one docs modal -->
    <v-dialog v-model="draftsDialog" width="1200">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Drafts</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="draftsDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedDocs"
                  :headers="draftsHeaders"
                  :items="drafts"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :search="search"
                >
                  <!-- start of vat sum template -->
                  <template v-slot:item.VatSum="{ item }">{{ item.VatSum.toLocaleString()}}</template>
                  <!-- end of vat sum template -->

                  <!-- start of doc total template -->
                  <template v-slot:item.DocTotal="{ item }">{{ item.DocTotal.toLocaleString()}}</template>
                  <!-- end of doc total template -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of docs modal -->
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    record: {},
    search: null,
    loading: false,
    singleSelect: true,
    selectedDocs: [],
    draftsDialog: false,
    itemsPerPage: 20,
    StartDate: null,
    EndDate: null,
    countries: [],
    draftDoc: {},
    drafts: [],
    docTypes: [
      { name: "A/R Invoice", value: 13 },
      { name: "A/R Down Payment Invoice", value: 203 },
      { name: "A/R Credit Memo", value: 14 },
      { name: "Delivery", value: 15 }
    ],
    frequencies: [
      { name: "Annually", value: "A" },
      { name: "Daily", value: "D" },
      { name: "Monthly", value: "M" },
      { name: "One Time", value: "O" },
      { name: "Quarterly", value: "Q" },
      { name: "Semiannually", value: "S" },
      { name: "Weekly", value: "W" }
    ],
    reminders: [
      { name: "On 1", value: 1001 },
      { name: "On 2", value: 1002 },
      { name: "On 3", value: 1003 },
      { name: "On 4", value: 1004 },
      { name: "On 5", value: 1005 },
      { name: "On 6", value: 1006 },
      { name: "On 7", value: 1007 },
      { name: "On 8", value: 1008 },
      { name: "On 9", value: 1009 },
      { name: "On 10", value: 1010 },
      { name: "On 11", value: 1011 },
      { name: "On 12", value: 1012 },
      { name: "On 13", value: 1013 },
      { name: "On 14", value: 1014 },
      { name: "On 15", value: 1015 },
      { name: "On 16", value: 1016 },
      { name: "On 17", value: 1017 },
      { name: "On 18", value: 1018 },
      { name: "On 19", value: 1019 },
      { name: "On 20", value: 1020 },
      { name: "On 21", value: 1021 },
      { name: "On 22", value: 1022 },
      { name: "On 23", value: 1023 },
      { name: "On 24", value: 1024 },
      { name: "On 25", value: 1025 },
      { name: "On 26", value: 1026 },
      { name: "On 27", value: 1027 },
      { name: "On 28", value: 1028 }
    ],
    draftsHeaders: [
      { text: "Doc No", value: "DocNum" },
      { text: "Tenant Code", value: "CardCode" },
      { text: "Tenant Name", value: "CardName" },
      { text: "Tax", value: "VatSum" },
      { text: "Doc Total", value: "DocTotal" }
    ]
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    openDraftsDialog() {
      this.draftsDialog = true;
    },
    clickedItem($event) {
      this.draftDoc = $event.item;
      this.draftsDialog = false;
    },
    setStartDate(date) {
      this.StartDate = date;
    },
    setEndDate(date) {
      this.EndDate = date;
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.record.DraftEntry = this.draftDoc.id;
      this.record.StartDate = this.StartDate;
      this.record.EndDate = this.EndDate;
      this.$emit("data", this.record);
    },
    getDrafts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/drafts`)
        .then(res => {
          self.drafts = res.data;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getDrafts();
  }
};
</script>